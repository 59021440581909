/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from 'react';
import "./css/LandingPage.css";
import RegisterModal from "./registrationModal";
// reactstrap components
import { Button, Container } from "reactstrap";
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link, animateScroll as scroll } from "react-scroll";
import MailingList from './Mailinglist';
import ContactModal from './ContactModal';


class LandingPageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liveDemo: false,
      setLiveDemo: true,
      contactLiveDemo: false,
      contactSetLiveDemo: true,
    }
  }

  closeModal = () => {
    this.setState({
      liveDemo: false,
      setLiveDemo: true,
    });
  }

  closeContactModal = () => {
    this.setState({
      contactLiveDemo: false,
      contactSetLiveDemo: true,
    });
  }

  componentDidMount() {
      if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        this.state.pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }

  render() {
    return(
    <>
      <div
        style={{
          backgroundImage: "url(" + require("../assets/img/books1.jpg") + ")"
        }}
        className="page-header"
        data-parallax={true}
        ref={this.state.pageHeader}
      >
        <div className="filter" />
        <Container id="container">
          <div className="motto text-center">
            <div className="cards"> 
              <Card>
                <CardBody>
                    <CardTitle className="card-title" id="title"> Our Mission </CardTitle>
                    <CardText className="card-text" id="text">To provide excellent educational services to students at an affordable price.</CardText>
                    <CardTitle className="card-title" id="title"> Our Strategy </CardTitle>
                    <CardText className="card-text" id="text">Student first is our policy and it is implemented by finding the student’s current level of competency through an assessment test.</CardText>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="card-title" id="title"> Why Sunsry? </CardTitle>
                  <CardText className="card-text" id="text" >To help students achieve high academic goals through enriched instruction and regular practice tests.</CardText>    
                  <CardText className="card-text" id="text" >To support parents by creating customized courses for an affordable price.</CardText>   
                  <CardText className="card-text" id="text" >To motivate students for realizing their full academic potential by employing challenging curriculum. </CardText>         
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                    <CardTitle className="card-title" id="title"> Online Open House 10am-12pm on 7/5/2020 Sunday  </CardTitle>
                    <CardText className="card-text" id="text">Please register to join the Open House for our promotional offers and referral discounts. </CardText>
                    
                    <CardTitle className="card-title" id="title"> Free Information session on TJ Research Statistics at 1pm on 7/5/2020 </CardTitle>
                   <CardText className="card-text" id="text">Pleaase register for free information session on TJ Research statics course that starts on 7/6/2020.</CardText>

                </CardBody>
              </Card>
{/*
              <Card>
                <CardBody>
                    <CardTitle className="card-title" id="title"> Free Tips for TJ Semifinalists @ 4pm on Sun 2/2/2020</CardTitle>
                    <CardText className="card-text" id="text"> Hear from the recent successful TJ students who will share their Tips
                              on how you can be successful at the TJ Semifinalist test. The TJ students would share the strategies and techniques which helped them to get admission into TJ.</CardText>
                   
                </CardBody>
              </Card>
      /*}      
              {/* <Card>
                <CardBody>
                    <CardTitle className="card-title" id="title"> INAUGURAL DISCOUNTS</CardTitle>
                    <CardText className="card-text" id="text"> Inaugural 5% discount for all courses </CardText>
                    <CardText className="card-text" id="text"> Additional 5% discount for full payment </CardText>
                    <CardText className="card-text" id="text"> Get $50 referral discount for each student you referred</CardText>
                    <CardText className="card-text" id="text"> * You may refer any number of students but the referred student has to pay full or at least 1 month course fee.</CardText>
                </CardBody>
              </Card> */}
              
            </div>

            <Button 
              className="btn-round mr-1"
              color="neutral"
              outline
              id="signup-btn"
              onClick={() => this.setState({contactLiveDemo: true})}
              > Contact Us
            </Button>

            <ContactModal liveDemo={this.state.contactLiveDemo} setLiveDemo={this.state.contactSetLiveDemo} handleClose={this.closeContactModal} />


            <Button
              className="btn-round mr-1"
              color="neutral"
              outline
              id="signup-btn"
              onClick={() => this.setState({liveDemo: true})}
            >
              Sign up for our mailing list!
            </Button>
            
            <MailingList liveDemo={this.state.liveDemo} setLiveDemo={this.state.setLiveDemo} handleClose={this.closeModal} />


          </div>
        </Container>
      </div>
    </>
    )
  }  
}

export default LandingPageHeader;


// core components

// function LandingPageHeader(props) {
//   let liveDemo = useState(false);
//   let setLiveDemo =  useState(true);
//   let closeModal = useState(false);

//   let pageHeader = React.createRef();

//   React.useEffect(() => {
//     if (window.innerWidth < 991) {
//       const updateScroll = () => {
//         let windowScrollTop = window.pageYOffset / 3;
//         pageHeader.current.style.transform =
//           "translate3d(0," + windowScrollTop + "px,0)";
//       };
//       window.addEventListener("scroll", updateScroll);
//       return function cleanup() {
//         window.removeEventListener("scroll", updateScroll);
//       };

//       function handleCloseModal(closeModal) {
//         liveDemo = false;
//         setLiveDemo =  true;
//       }

//     }
//   });

//   return (
//     <>
//       <div
//         style={{
//           backgroundImage: "url(" + require("../assets/img/books1.jpg") + ")"
//         }}
//         className="page-header"
//         data-parallax={true}
//         ref={pageHeader}
//       >
//         <div className="filter" />
//         <Container id="container">
//           <div className="motto text-center">
//             <div className="cards"> 
//               <Card style={{width: '18rem', height: '31rem'}}>
//                 <CardBody>
//                     <CardTitle className="card-title" id="title"> Our Mission </CardTitle>
//                     <CardText className="card-text" id="text">To provide excellent educational services to students at an affordable price.</CardText>
//                 </CardBody>
//               </Card>

//               <Card style={{width: '18rem', height: '31rem'}}>
//                 <CardBody>
//                     <CardTitle className="card-title" id="title"> Our Strategy </CardTitle>
//                     <CardText className="card-text" id="text">Student first is our policy and it is implemented by finding the student’s current level of competency through an assessment test.</CardText>
//                 </CardBody>
//               </Card>

//               <Card style={{width: '18rem', height: '31rem'}}>
//                 <CardBody>
//                     <CardTitle className="card-title" id="title"> Why Sunsry? </CardTitle>
//                     <CardText className="card-text" id="text">We are here to help students and parents. We help students by genuinely caring for their progress and we help parents by charging them very reasonable price for the quality services we provide. </CardText>
//                 </CardBody>
//               </Card>

//               <Card style={{width: '18rem', height: '31rem'}}>
//                 <CardBody>
//                     <CardTitle className="card-title" id="title"> Open Houses in Nov</CardTitle>
//                     <CardText className="card-text" id="text"> Please come to the Open House on Fri 11/29/19 OR Sat 11/30/19 2.00pm-4.00pm to meet our teachers and take advantage of our big promotional offers. </CardText>
//                     <CardTitle className="card-title" id="title"> Discounts</CardTitle>
//                     <CardText className="card-text" id="text"> 15% Promotional discount with additional referral discount of $50 for each new student referral </CardText>
//                 </CardBody>
//               </Card>
//             </div>

//             <Button
//               href="/testimonials"
//               className="btn-round mr-1"
//               color="neutral"
//               target="_blank"
//               outline
//               id="signup-btn"
//             >
//               Sign up

//             </Button>


//             <Button  color="primary" type="button" onClick={() => ({liveDemo: true})}>
//           Register Now!
//         </Button>

//         <mailingList liveDemo={liveDemo} setLiveDemo={setLiveDemo} handleCloseModal={closeModal} />


//             <Button 
//               className="btn-round mr-1"
//               color="neutral"
//               outline
//               id="signup-btn"
//               >
//               <Link
//                   activeClass="active"
//                   to="section8"
//                   spy={true}
//                   smooth={true}
//                   offset={-90}
//                   duration={700}
//                 >
//                 <h5 id="contact"> Contact Us</h5>
//                 </Link>
//             </Button>
//           </div>
//         </Container>
//       </div>
//     </>
//   );
// }

// export default LandingPageHeader;
