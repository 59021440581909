import React from "react";
import Component from "react";
import './css/Testimonials.css';
import { isMetaProperty } from "@babel/types";


class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonials: this.props.testimonials,
    }
  }

  render() {
    let testimonials; 
    if (this.props.testimonials) {
      testimonials = this.props.testimonials.map((item) => 
        <div key={item.studentName} id="test-cards" className="class-card">
          <img className="image" src={item.src} /> 
          <div className="flex-div">
            <h4 id="names"> Student: {item.studentName} </h4>
          </div> 
          <div className="flex-div">
            <h4 id="course"> Course: {item.coursetaken} </h4>
          </div> 
          
          <div id="course-container">
            {/* <div class="flex-div">
              <h4 id="names"> Parent: </h4>  <h4 id="names"> {item.parentName} </h4>
            </div>  */}
            <h5 id="summary"> {item.summary} </h5>

          </div>
         
          
        </div>
      )
    }
    return(
      <div className={"section" + (this.props.dark ? " section-dark" : "")}>
      <div className="section-content" id={this.props.id}>
        <h1 className="title"> Testimonials </h1>

        <div id="section-container">
          {testimonials}
        </div>       
      </div>
    </div>
    )
  }

    
}

export default Testimonials;