import React from "react";
// reactstrap components
import { Button, FormGroup, Input, Modal, Label } from "reactstrap";
import NumberFormat from 'react-number-format';
import './css/section.css';

class registrationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      enable: true,
      form: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleChange(event) {
    this.setState({value: event.target.value});
    this.handleSubmit(event);
  }

  handleSubmit(event) {
    if (event.target.value.length === 5) {
      this.setState({enable: false});
    } else {
      this.setState({enable: true});
    }
  }

  openConfirmationModal() {
    this.setState({
      form: true
    })
  }





  // handleChange(e) {
  //   console.log(e.target)
  //   this.setState({
  //     zip: e.target.zip
  //   })

    // console.log("value!", this.state.zip)
    // if (this.state.zip.length) {
    //   this.setState({
    //     validate: false
    //   })
    // }
      
  // }

  render() {
    var stateOptions = [];
    let states = ['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming'];
    if (states) {
      for (var i = 0; i < states.length; i++) {
        stateOptions.push( <option key={i} value={states[i]}> {states[i]} </option> )
      }
    }
    
    var courses = [];
    if (this.props.options) {
      let options = this.props.options
      for (var i = 0; i < options.length; i++) {
        courses.push( <option key={i} value={options[i].name}> {options[i].name} </option> )
      }
    }

    return(
<>

  <Modal isOpen={this.props.liveDemo} toggle={() => this.props.handleClose()}>
    <div id="form" display={this.state.form}> 
      <meta HTTP-EQUIV="Content-type" CONTENT="text/html; charset=UTF-8" />
      <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
        <input type='hidden' name="oid" value="00D3i000000vMZ8" />
        <input type='hidden' name="retURL" value="https://sunsry.com/success" />
            <div className="modal-header">
              <h5 className="modal-title" id="registration">
                Registration Form
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.handleClose() }
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-row" >
                <FormGroup className="col-md-12">
                  <Label for="prepcourse"> Course </Label>
                  <Input class="courseinput" type="select" id="00N3i00000CDp4Z" name="00N3i00000CDp4Z" title="Prep Course" >
                  {courses}
                  </Input>  
                </FormGroup>
              </div>
              <div id="courselabel" className="form-row" >
                <FormGroup className="col-md-12">
                  <Label for="grade">Student Grade</Label>
                  <Input id="00N3i00000CDoBB" name="00N3i00000CDoBB" title="Student Grade/Class" type="select" >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="Other">Other</option>
                  </Input>
                </FormGroup>
              </div> 
              <div className="form-row">
                <Label className="form-label">Parent Details</Label>
              </div>
              <div className="form-row">
                <FormGroup className="col-md-6"> 
                  <Label for="inputEmail4">First Name</Label>
                  <Input required id="00N3i00000CDp4e" maxLength="25" name="00N3i00000CDp4e" type="text" placeholder="First Name"/>
                </FormGroup>
                <FormGroup className="col-md-6">
                  <Label for="inputPassword4">Last Name</Label>
                  <Input required id="00N3i00000CDp4j" maxLength="25" name="00N3i00000CDp4j" type="text" placeholder="Last Name"/>
                </FormGroup>
                <FormGroup className="col-md-6"> 
                  <Label for="inputEmail4">Email</Label>
                  <Input required id="00N3i00000CDoBk" maxLength="80" name="00N3i00000CDoBk" type="email" placeholder="Email"/>
                  <p class="undercaption" for="inputPassword4">Format: sample@email.com </p>
                </FormGroup>
                <FormGroup className="col-md-6"> 
                  <Label for="inputEmail4">Alternate Email</Label>
                  <Input maxLength="80" type="email" id="email" name="email" placeholder="Email"/>
                  <p class="undercaption" for="inputPassword4">Format: sample@email.com </p>
                </FormGroup>
                <FormGroup className="col-md-6">
                  <Label for="inputPassword4">Phone</Label>
                  <NumberFormat class="form-control" required id="00N3i00000CDoCY" maxLength="15" name="00N3i00000CDoCY" type="tel" placeholder="Phone" format="(###) ###-####" mask="_"/>
                </FormGroup>
                <FormGroup className="col-md-6">
                  <Label for="inputPassword4">Alternate Phone</Label>
                  <NumberFormat class="form-control" id="phone" name="phone" type="tel" placeholder="Phone" format="(###) ###-####" mask="_"/>
                </FormGroup>
              </div>
              <div className="form-row">
                <Label className="form-label">Student Details</Label>
              </div>
              <div className="form-row">
                <FormGroup className="col-md-6"> 
                  <Label required for="inputEmail4">First Name</Label>
                  <Input id="00N3i00000CDo9y" maxLength="25" name="00N3i00000CDo9y" type="text" placeholder="First Name"/>
                </FormGroup>
                <FormGroup className="col-md-6">
                  <Label required for="inputPassword4">Last Name</Label>
                  <Input id="00N3i00000CDoAD" maxLength="25" name="00N3i00000CDoAD" type="text" placeholder="Last Name"/>
                </FormGroup>
                <FormGroup className="col-md-6"> 
                  <Label for="inputEmail4">Email</Label>
                  <Input id="00N3i00000CDoBz" maxLength="80" name="00N3i00000CDoBz" type="email" placeholder="Email"/>
                  <p class="undercaption" for="inputPassword4">Format: sample@email.com </p>
                </FormGroup>
                <FormGroup className="col-md-6">
                  <Label for="inputPassword4">Phone</Label>
                  <NumberFormat class="form-control" required id="00N3i00000CDoCi" name="00N3i00000CDoCi" type="tel" placeholder="Phone" format="(###) ###-####" mask="_"/>
                </FormGroup>
              </div>
                <FormGroup>
                  <Label for="inputAddress">Address</Label>
                  <Input required type="text" id="street" name="street" placeholder="1234 Main St"/>
                </FormGroup>
                <FormGroup>
                  <Label for="inputAddress2">Address 2</Label>
                  <Input type="text" id="00N3i00000CFJRK" name="00N3i00000CFJRK" placeholder="Apartment, studio, or floor"/>
                </FormGroup>
                <div className="form-row">
                  <FormGroup className="col-md-4">
                    <Label for="inputCity">City</Label>
                    <Input required type="text" id="city" maxLength="40" name="city"/>
                  </FormGroup>
                  <FormGroup className="col-md-5">
                    <Label for="inputState">State</Label>
                    <Input required type="select" id="state" name="state" defaultValue='Virginia'>
                      <option>Choose...</option>
                      {stateOptions}
                    </Input>
                  </FormGroup>
                  <FormGroup className="col-md-3">
                    <Label for="inputZip">Zip</Label>
                    <Input type="text" value={this.state.value} onChange={this.handleChange} required id="zip" name="zip"  />
                    <p class="undercaption" for="inputZip"> Format: 12345 </p>
                  </FormGroup>
                </div>
            </div>
            <div className="modal-footer">
              <div className="left-side">
                <Button
                  className="btn-link"
                  color="default"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.props.handleClose() }
                >
                  Cancel
                </Button>
              </div>
              <div className="divider" />
              <div className="right-side">
                <Button
                  className="btn-link"
                  color="success"
                  type="submit"
                  name="submit"
                  disabled={this.state.enable}
                  id="gray"
                  onSubmit={this.openConfirmationModal}
                >
                  Submit
                </Button>
              </div>
            </div>
            </form>
          </div>
      </Modal>
    </>
    )
  }  
}

export default registrationModal;