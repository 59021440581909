/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import logo from "../assets/squarelogo.png"
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";
import "./css/nav.css";
// core components
import { Link, animateScroll as scroll } from "react-scroll";

function nav() {

  const [navbarColor, setNavbarColor] = React.useState("info");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <Navbar className={classnames("fixed-top bg-info")} id="navbar" expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/"
            title="SunSry"
          >
            <img className="logo" src={logo}/>
            Sunsry
            <p class="italics"> TestPrep & Training </p>
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
{/*
            <NavItem>
                <Link
                activeClass="active"
                to="section1"
                spy={true}
                smooth={true}
                offset={-100}
                duration={700}
                className="nav-link"
              >
               <h6 id="bold"> SATPrep  </h6>
              </Link>
            </NavItem>
*/}
            <NavItem>
              {/* <NavLink
                data-placement="bottom"
                title="TestPreps"
              > */}
                 <Link
                activeClass="active"
                to="section2"
                spy={true}
                smooth={true}
                offset={-100}
                duration={700}
                className="nav-link"

              >
               <h6 id="bold"> TestPreps </h6>
              </Link>
            </NavItem>

            <NavItem>
                 <Link
                activeClass="active"
                to="section3"
                spy={true}
                smooth={true}
                offset={-100}
                duration={700}
                className="nav-link"

              >
               <h6 id="bold"> Math </h6>
              </Link>
            </NavItem>

            <NavItem>
              <Link
                activeClass="active"
                to="section4"
                spy={true}
                smooth={true}
                offset={-100}
                duration={700}
                className="nav-link"

              >
               <h6 id="bold"> English </h6>
              </Link>
            </NavItem>

            <NavItem>
              {/* <NavLink
                data-placement="bottom"
                title="Science"
              > */}
                 <Link
                activeClass="active"
                to="section5"
                spy={true}
                smooth={true}
                offset={-100}
                duration={700}
                className="nav-link"

              >
                
               <h6 id="bold"> Science </h6>
              </Link>
              {/* </NavLink> */}
            </NavItem>

            {/* */}
            <NavItem>
              <NavLink
                data-placement="bottom"
                title="More"
              >
                 <Link
                activeClass="active"
                to="section6"
                spy={true}
                smooth={true}
                offset={-90}
                duration={700}
              >
               <h6 id="bold"> More </h6>
              </Link>
              </NavLink>
            </NavItem> 

            <NavItem>
              {/* <NavLink
                data-placement="bottom"
                title="Testimonials"
              > */}
                 <Link
                activeClass="active"
                to="section7"
                spy={true}
                smooth={true}
                offset={-100}
                duration={700}
                className="nav-link"

              >
               <h6 id="bold"> Testimonials </h6>
              </Link>
              {/* </NavLink> */}
            </NavItem>

            <NavItem>
              {/* <NavLink
                data-placement="bottom"
                title="Contact"
              > */}
                 <Link
                activeClass="active"
                to="section8"
                spy={true}
                smooth={true}
                offset={-100}
                duration={700}
                className="nav-link"

              >
               <h6 id="bold"> Contact</h6>
              </Link>
              {/* </NavLink> */}
            </NavItem>
           

          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default nav;
