import React from "react";
import "./css/success.css"
// import Component from "react";

class Success extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   liveDemo: false,
    //   setLiveDemo: true,
    //   class: props.title,
    //   options: props.availablecourses
    // }
  }
  

  render() {
    return(
      <div class="success-container">
        <h1 className="success-header"> Success! </h1>
        <p className="success"> Thank you for submitting your information. We will contact you as soon as we can. If you need a response right away, 	please text/call 703-517-3614 or 703-517-0972 or send an email to admin@sunsry.com for immediate attention. </p>
      </div>
    )
  }

    
}

export default Success;