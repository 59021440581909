import React from "react";
import Component from "react";
import { Button, FormGroup, Input, Modal, Label,  Row, Col } from "reactstrap";
import RegisterModal from "./registrationModal";
import "./css/section.css";

class Section extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liveDemo: false,
      setLiveDemo: true,
      class: props.title,
      options: props.availablecourses
    }
  }
  
  closeModal = () => {
    this.setState({
      liveDemo: false,
      setLiveDemo: true
    });
  }

  render() {
    let courses;    
    if (this.props.availablecourses) {
      courses = this.props.availablecourses.map((item) => 
        <div key={item.name} className="class-card">
          <h4 id="classes">{item.name} </h4> 
          <p className="desc"> {item.desc} </p>
          <p className="details">  <a id="detcolor" href={item.detailurl}> {item.details} </a> </p>
  
      
          <div className="bottom-container"> 
            <p className="other"> <b> Price: </b> {item.price} </p>
            <p className="other"> <b> Date: </b> {item.startdate} -  {item.enddate} </p>
          </div>
        </div>
      )
    }
    return(
    <div className={"section" + (this.props.dark ? " section-dark" : "")}>
      <div className="section-content" id={this.props.id}>

        <h1 className="title">{this.props.title}</h1>
        <h3 className="subtitle">{this.props.summary}</h3>

        <div id="section-container">
          { courses } 
        </div>

        <Button className={"register" + (this.props.dark ? "-dark" : "")}  color="primary" type="button" onClick={() => this.setState({liveDemo: true})}>
          Register Now!
        </Button>

        <RegisterModal options={this.props.availablecourses} class={this.props.title} liveDemo={this.state.liveDemo} setLiveDemo={this.state.setLiveDemo} handleClose={this.closeModal} />
      </div>
    </div>
    )
  }

    
}

export default Section;