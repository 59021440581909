import React from 'react';
import Nav from './components/nav';
import Section from './components/section';
import Contact from './components/Contact';
import Testimonials from './components/Testimonials';
import content from './content';
import LandingPageHeader from "./components/LandingPageHeader.js";
import Ticker from 'react-ticker';
import Success from './components/Success';
import {
  BrowserRouter as Router,
  Link,
  Switch,
  Route,
} from "react-router-dom";
import {
  NavbarBrand,
  Navbar,
  Container
} from "reactstrap";
import logo from "./assets/squarelogo.png"


// import FontAwesome from 'react-fontawesome';
import FontAwesome from 'react-fontawesome'
// import faStyles from 'font-awesome/css/font-awesome.css'
import './App.css';

function App() { 
  var index = ["Open House: On Jul 5, @10am ET", "Open House: On Jul 5, @10am ET"]
  return (
    <div className="App">
       <Router>
        <Switch>
          <Route path="/success">
            
            <Navbar  id="navbar" expand="lg" className="fixed-top bg-info navbar navbar-expand-lg">
            <Container>
              <div className="navbar-translate">
                <NavbarBrand
                  data-placement="bottom"
                  href="/index"
                  target="_blank"
                  title="SunSry"
                >
                  <img className="logo" src={logo}/>
                  Sunsry
                  <p class="italics"> for TestPrep & Tutoring </p>
                </NavbarBrand>
    
                </div>
              </Container>
            </Navbar>
            <Ticker
              class="success-ticker"
              offset="20"
              mode="smooth"
              number="4"
            >
              {() => (
                <h3 className="promotions">  
                <FontAwesome
                  className="fas fa-angle-double-left icon"
                  name="angle-double-left"
                />
                {index[0]}
                <FontAwesome
                  className="fas fa-angle-double-right icon"
                  name="angle-double-right"
                />
                <FontAwesome
                  className="fas fa-angle-double-left icon"
                  name="angle-double-left"
                />
                {index[1]}
                <FontAwesome
                  className="fas fa-angle-double-right icon"
                  name="angle-double-right"
                />

                </h3>
              )}
              </Ticker> 
            <Success />
          </Route>

          <Route path="/">
            <Nav />
            <Ticker
              offset="20"
              mode="smooth"
              number="4"
            >
              {() => (
                <h3 className="promotions">  
                <FontAwesome
                  className="fas fa-angle-double-left icon"
                  name="angle-double-left"
                />
                {index[0]}
                <FontAwesome
                  className="fas fa-angle-double-right icon"
                  name="angle-double-right"
                />
                <FontAwesome
                  className="fas fa-angle-double-left icon"
                  name="angle-double-left"
                />
                {index[1]}
                <FontAwesome
                  className="fas fa-angle-double-right icon"
                  name="angle-double-right"
                />
                </h3>
              )}
            </Ticker> 
            <LandingPageHeader />
            <Section
              props={content[0]}
              title={content[0].title}
              summary={content[0].summary}
              price={content[0].price}
              availablecourses={content[0].availablecourses}
              dark={false}
              id="section1">
            </Section>
            <Section
              title={content[1].title}
              summary={content[1].summary}
              price={content[1].price}
              availablecourses={content[1].availablecourses}
              dark={true}
              id="section2">
            </Section>

            <Section
              title={content[2].title}
              summary={content[2].summary}
              price={content[2].price}
              availablecourses={content[2].availablecourses}
              dark={false}
              id="section3">
            </Section>

            <Section
              title={content[3].title}
              summary={content[3].summary}
              price={content[3].price}
              availablecourses={content[3].availablecourses}
              dark={true}
              id="section4">
            </Section>

            <Section
              title={content[4].title}
              summary={content[4].summary}
              price={content[4].price}
              availablecourses={content[4].availablecourses}
              dark={false}
              id="section5">
            </Section>

            <Section
              title={content[5].title}
              summary={content[5].summary}
              price={content[5].price}
              availablecourses={content[5].availablecourses}
              dark={true}
              id="section6">
            </Section>
            

            <Testimonials
              title={content[6].title}
              props={content[6]}
              testimonials={content[6].testimonials}
              dark={false}
              id="section7">
            </Testimonials>
      
            <Contact
              title={content[7].title}
              props={content[7]}
              dark={true}
              id="section8">
            </Contact>
    
          </Route>
        </Switch>
      </Router>


      
       
    </div>
  );
}

export default App;
