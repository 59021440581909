import React from "react";
// reactstrap components
import { Button, FormGroup, Input, Modal, Label } from "reactstrap";
import NumberFormat from 'react-number-format';
import { Formik } from 'formik';
import './css/Contact.css';

class ContactModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // done: false,
      // message: ''
    }
    // this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {

    return(
<>
  <Modal isOpen={this.props.liveDemo} toggle={() => this.props.handleClose()}>
    <meta HTTP-EQUIV="Content-type" CONTENT="text/html; charset=UTF-8" />
    <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
      <input type='hidden' name="oid" value="00D3i000000vMZ8" />
      <input type='hidden' name="retURL" value="https://www.sunsry.com" />
          <div className="modal-header">
            <h5 className="modal-title" id="registration">
              Contact Us
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.props.handleClose() }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="form-row">
              <Label className="form-label">Parent Details</Label>
            </div>
            <div className="form-row">
              <FormGroup className="col-md-6"> 
                <Label for="inputEmail4">First Name</Label>
                <Input required maxLength="25" type="text"  id="first_name" name="first_name" placeholder="First Name"/>
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="inputPassword4">Last Name</Label>
                <Input required maxLength="25" type="text"  id="last_name" name="last_name" placeholder="Last Name"/>
              </FormGroup>
              <FormGroup className="col-md-6"> 
                <Label for="inputEmail4">Email</Label>
                <Input required maxLength="80" type="email" id="email" name="email" placeholder="Email"/>
                <p class="undercaption" for="inputPassword4">Format: sample@email.com </p>
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="inputPassword4">Phone</Label>
                <NumberFormat class="form-control" required id="phone" name="phone" maxLength="15" type="tel" placeholder="Phone" format="(###) ###-####" mask="_"/>
              </FormGroup>
            </div>

            <div className="form-row">
              <Label className="form-label">Best Time to Contact me for any questions/concerns</Label>
            </div>
            <div className="form-row">
              {/*
              <FormGroup className="col-md-4" check>
                <Label check>
                  <Input id="00N3i00000CFYx7" name="00N3i00000CFYx7" type="checkbox" value="Morning" />{' '}
                  Morning
                  <span className="form-check-sign">
                    <span className="check"></span>
                </span>
                </Label>
                <Label check>
                  <Input id="00N3i00000CFYx7" name="00N3i00000CFYx7" type="checkbox" value="1" />{' '}
                  Afternoon 
                  <span className="form-check-sign">
                    <span className="check"></span>
                </span>
                </Label>
                <Label check>
                  <Input id="00N3i00000CFYx7" name="00N3i00000CFYx7" type="checkbox" value="1" />{' '}
                  Evening
                  <span className="form-check-sign">
                    <span className="check"></span>
                </span>
                </Label>
              </FormGroup>  
              */}
              <FormGroup id="time-info" className="col-md-4"> 
                <Input type="textarea"  maxlength="45" id="00N3i00000CFYx7" name="00N3i00000CFYx7" placeholder="Best time to contact"/>
              </FormGroup>

              <FormGroup className="col-md-8"> 
                <Input type="textarea"  id="00N3i00000CFU8Z" name="00N3i00000CFU8Z" id="add-info" placeholder="Additional Information / Questions"/>
            </FormGroup>
            </div>

          </div>
          <div className="modal-footer">
            <div className="left-side">
              <Button
                className="btn-link"
                color="default"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.handleClose() }
              >
                Cancel
              </Button>
            </div>
            <div className="divider" />
            <div className="right-side">
              <Button
                className="btn-link"
                color="success"
                type="submit"
                name="submit"
                onClick={() => this.props.handleSubmit() }
                id="gray"
              >
                Submit
              </Button>
            </div>
          </div>
          </form>
      </Modal>
    </>
    )
  }  
}

export default ContactModal;