import React from "react";
import "./css/Contact.css";
import Component from "react";
import { Button, FormGroup, Input, Modal, Label,  Row, Col } from "reactstrap";
import ContactModal from "./ContactModal";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liveDemo: false,
      setLiveDemo: true,
      class: props.title,
    }
    
  }

  closeModal = () => {
    this.setState({
      liveDemo: false,
      setLiveDemo: true
    });
  }
  

  render() {
    return(
      <div className={"section" + (this.props.dark ? " section-dark" : "")} id="contact-us-section">
      <h1 className="title"> Contact Us! </h1>
      <div className="content" id={this.props.id}>


        <h3 className="address"> <b> Email: </b> <a href="mailto:admin@sunsry.com"> admin@sunsry.com </a> </h3>
        <h3 className="address"> <b> Phone: </b> 703-517-3614 Or 703-517-0972 </h3>
        <h3 className="address"> <b> Address: </b> 4451 Brookfield Corporate Drive, Suite# 206, Chantilly VA 20151 </h3>
        
          <div className="mapouter"><div className="gmap_canvas">
            <iframe width="763" height="322" id="gmap_canvas" src="https://maps.google.com/maps?q=4451%20Brookfield%20Corporate%20Drive%2C%20Chantilly%20VA%2020151%20&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
            </iframe>
          </div>

        <div className="contact-us">
          <h3 className="contact-text"> Want us to contact you? Please fill   
          <Button className="contact-btn"  color="primary" type="button" onClick={() => this.setState({liveDemo: true})}>
            this form!
          </Button>
          </h3>
        </div>
       
        <ContactModal class={this.props.title} liveDemo={this.state.liveDemo} setLiveDemo={this.state.setLiveDemo} handleClose={this.closeModal} />

        </div>

       
      </div>
    </div>
    )
  }

    
}

export default Contact;