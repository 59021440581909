import React from "react";
// reactstrap components
import { Button, FormGroup, Input, Modal, Label } from "reactstrap";
import './css/section.css';

class MailingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enable: true
    }
  }

  render() {
    return(
      <>
        <Modal id="mailModal" isOpen={this.props.liveDemo} toggle={() => this.props.handleClose()}>
        <div id="form" display={this.state.form}> 
          <meta HTTP-EQUIV="Content-type" CONTENT="text/html; charset=UTF-8" />
          <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
            <input type='hidden' name="oid" value="00D3i000000vMZ8" />
            <input type='hidden' name="retURL" value="https://sunsry.com" />    
                <div className="modal-header">
                  <h5 className="modal-title" id="registration">
                    Join our Mailing List
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.props.handleClose() }
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                
                <div className="modal-body">
                  <div className="form-row" >
                    <FormGroup className="col-md-12"> 
                      <Label for="inputEmail4">Email</Label>
                      <Input required maxLength="80" id="email" name="email" type="email" placeholder="Email"/>
                      <p class="undercaption" for="inputPassword4">Format: sample@email.com </p>
                    </FormGroup>
                  </div>
                </div>
                
                <div className="modal-footer">
                  <div className="left-side">
                    <Button
                      className="btn-link"
                      color="default"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.props.handleClose() }
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="divider" />
                  <div className="right-side">
                    <Button
                      className="btn-link"
                      color="success"
                      type="submit"
                      name="submit"
                      id="gray"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
                </form>
              </div>
          </Modal>
      </>
    )
  }  
}

export default MailingList;